@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
.font-primary-light {
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.font-primary-regular {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-primary-medium {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.font-primary-bold {
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.vh-5 {
  height: 5dvh !important;
}

.vh-10 {
  height: 10dvh !important;
}

.vh-15 {
  height: 15dvh !important;
}

.vh-20 {
  height: 20dvh !important;
}

.vh-25 {
  height: 25dvh !important;
}

.vh-30 {
  height: 30dvh !important;
}

.vh-35 {
  height: 35dvh !important;
}

.vh-40 {
  height: 40dvh !important;
}

.vh-45 {
  height: 45dvh !important;
}

.vh-50 {
  height: 50dvh !important;
}

.vh-55 {
  height: 55dvh !important;
}

.vh-60 {
  height: 60dvh !important;
}

.vh-65 {
  height: 65dvh !important;
}

.vh-70 {
  height: 70dvh !important;
}

.vh-75 {
  height: 75dvh !important;
}

.vh-80 {
  height: 80dvh !important;
}

.vh-85 {
  height: 85dvh !important;
}

.vh-90 {
  height: 90dvh !important;
}

.vh-95 {
  height: 95dvh !important;
}

.vh-100 {
  height: 100dvh !important;
}

.vw-5 {
  width: 5dvw !important;
}

.vw-10 {
  width: 10dvw !important;
}

.vw-15 {
  width: 15dvw !important;
}

.vw-20 {
  width: 20dvw !important;
}

.vw-25 {
  width: 25dvw !important;
}

.vw-30 {
  width: 30dvw !important;
}

.vw-35 {
  width: 35dvw !important;
}

.vw-40 {
  width: 40dvw !important;
}

.vw-45 {
  width: 45dvw !important;
}

.vw-50 {
  width: 50dvw !important;
}

.vw-55 {
  width: 55dvw !important;
}

.vw-60 {
  width: 60dvw !important;
}

.vw-65 {
  width: 65dvw !important;
}

.vw-70 {
  width: 70dvw !important;
}

.vw-75 {
  width: 75dvw !important;
}

.vw-80 {
  width: 80dvw !important;
}

.vw-85 {
  width: 85dvw !important;
}

.vw-90 {
  width: 90dvw !important;
}

.vw-95 {
  width: 95dvw !important;
}

.vw-100 {
  width: 100dvw !important;
}

.color-primary {
  color: #13a5b6 !important;
}

.bp {
  background-color: #13a5b6 !important;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.text-justify {
  text-align: justify;
}

.font-weight-bolder {
  font-weight: bolder;
}

.cursor-pointer {
  cursor: pointer !important;
}/*# sourceMappingURL=index.css.map */