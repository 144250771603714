@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

$weights: (
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700
);

$dimensions: (
  5 : 5,
  10: 10,
  15: 15,
  20: 20,
  25: 25,
  30: 30,
  35: 35,
  40: 40,
  45: 45,
  50: 50,
  55: 55,
  60: 60,
  65: 65,
  70: 70,
  75: 75,
  80: 80,
  85: 85,
  90: 90,
  95: 95,
  100: 100,
);

@mixin ubuntu-font($weight, $style: normal) {
  font-family: "Ubuntu", sans-serif;
  font-weight: $weight;
  font-style: $style;
}

@each $name, $weight in $weights {
  .font-primary-#{$name} {
    @include ubuntu-font($weight);
  }
}

@mixin height-manager($height) {
  height: $height !important;
}

@each $name, $dimension in $dimensions {
  .vh-#{$name} {
    @include height-manager($dimension + dvh)
  }
}

@mixin width-manager($width) {
  width: $width !important;
}

@each $name, $dimension in $dimensions {
  .vw-#{$name} {
    @include width-manager($dimension + dvw)
  }
}

.color-primary {
  color: #13a5b6 !important;
}

.bp{
  background-color: #13a5b6 !important;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.text-justify {
  text-align: justify;
}

.font-weight-bolder{
  font-weight: bolder;
}

.cursor-pointer{
  cursor: pointer !important;
}